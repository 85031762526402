export const fetchData = async (url, handleError) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  } catch (error) {
    handleError(error.message);
    throw error; // Re-throw the error to handle it in the component if needed
  }
};
