import React, { useEffect, useState } from "react";
import img1 from "../assests/pngs/icon1.png";
import img2 from "../assests/pngs/icon2.png";
import img3 from "../assests/pngs/icon3.png";
import img4 from "../assests/pngs/icon4.png";
import { fetchData } from "../api";
import ErrorModal from "./ErrorModal";

const Cards = () => {
  const [statsData, setStatsData] = useState();
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleError = (error) => {
    setErrorMessage(error);
    setShowModal(true);
    setIsLoading(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  const test = async () => {
    try {
      const res = await fetchData(
        `${process.env.REACT_APP_GNOSIS_CARDS_STATS}`
      );
      setStatsData(res);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError("Something went wrong. Please try again later 1");
    }
  };
  useEffect(() => {
    test();
  }, []);

  return (
    <div className="flex items-center justify-center font-poppins">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-16 items-end text-white sm:h-[200px] md:h-[450px] mx-auto">
        <div className="flex flex-col justify-center items-center w-64 my-2 sm:my-3 rounded-lg h-32 md:h-36 bg-[#040F1C] bg-opacity-90">
          <div className="flex justify-center border border-[#40576A] rounded-lg bg-[#354D61] w-8 h-8 sm:w-10 sm:h-10 mt-3 mb-2">
            <img src={img1} alt="" className="" />
          </div>
          <div className="flex flex-col items-center mb-3">
            <span className="text-[14px] sm:text-[16px] md:text-[18px] font-semibold text-[#9CA0A7] font-poppins">
              Avg. Block Time
            </span>
            <span className="text-[14px] sm:text-[18px] md:text-[24px] font-poppins font-semibold">
              {(statsData &&
                (statsData?.average_block_time / 1000).toFixed(1)) ||
                0}{" "}
              sec
            </span>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center w-64 my-2 sm:my-3 rounded-lg h-32 md:h-36 bg-[#040F1C] bg-opacity-90">
          <div className="flex justify-center border border-[#40576A] rounded-lg bg-[#354D61] w-8 h-8 sm:w-10 sm:h-10 mt-3 mb-2">
            <img src={img2} alt="" className="" />
          </div>
          <div className="flex flex-col items-center mb-3">
            <span className="text-[14px] sm:text-[16px] md:text-[18px] font-semibold text-[#9CA0A7] font-poppins">
              Total Transactions
            </span>
            <span className="text-[14px] sm:text-[18px] md:text-[24px] font-poppins font-semibold">
              {(statsData && statsData?.total_transactions) || 0}
            </span>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center w-64 my-2 sm:my-3 rounded-lg h-32 md:h-36 bg-[#040F1C] bg-opacity-90">
          <div className="flex justify-center border border-[#40576A] rounded-lg bg-[#354D61] w-8 h-8 sm:w-10 sm:h-10 mt-3 mb-2">
            <img src={img3} alt="" className="" />
          </div>
          <div className="flex flex-col items-center mb-3">
            <span className="text-[14px] sm:text-[18px] md:text-[24px] font-semibold text-[#9CA0A7] font-poppins">
              Total Blocks
            </span>
            <span className="text-[18px] sm:text-[24px] font-poppins font-semibold">
              {(statsData && statsData?.total_blocks) || 0}
            </span>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center w-64 my-2 sm:my-3 rounded-lg h-32 md:h-36 bg-[#040F1C] bg-opacity-90">
          <div className="flex justify-center border border-[#40576A] rounded-lg bg-[#354D61] w-8 h-8 sm:w-10 sm:h-10 mt-3 mb-2">
            <img src={img4} alt="" className="" />
          </div>
          <div className="flex flex-col items-center mb-3">
            <span className="text-[14px] sm:text-[16px] md:text-[18px] font-semibold text-[#9CA0A7] font-poppins">
              Wallet Addresses
            </span>
            <span className="text-[14px] sm:text-[18px] md:text-[24px] font-poppins font-semibold">
              {(statsData && statsData?.total_addresses) || 0}
            </span>
          </div>
        </div>
      </div>
      <ErrorModal
        show={showModal}
        title="Error"
        message={errorMessage}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
};

export default Cards;
