import React, { useState } from "react";
import SearchBar from "../components/SearchBar.jsx";
import { useEffect } from "react";
import { IoCopyOutline } from "react-icons/io5";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { fetchData } from "../api.js";
import { ring2 } from "ldrs";
import ErrorModal from "../components/ErrorModal.jsx";
import Pagination from "../components/Pagination.jsx";

const Assests = () => {
  const [statsData, setStatsData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleError = (error) => {
    setErrorMessage(error);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  const test = async () => {
    try {
      const res = await fetchData(`${process.env.REACT_APP_GNOSIS_TOKENS}`);
      setStatsData(res.items);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError("Something went wrong. Please try later.");
      console.error("Error caught in loadData:", error.message);
    }
  };

  useEffect(() => {
    test();
  }, []);

  function copyText1(arr) {
    /* Copy text into clipboard */
    navigator.clipboard.writeText(arr?.address);
  }

  const [currentpage, setCurrentpage] = useState(1);
  const recordsperpage = 10;
  const indexoflastrecord = currentpage * recordsperpage;
  const indexoffirstrecord = indexoflastrecord - recordsperpage;
  const records = statsData.slice(indexoffirstrecord, indexoflastrecord);
  const nPages = Math.ceil(statsData.length / recordsperpage);
  const numbers = [...Array(nPages + 1).keys()].slice(1);

  function prePage(e) {
    e.preventDefault();
    if (currentpage !== 1) {
      setCurrentpage(currentpage - 1);
    }
  }
  function nextPage(e) {
    e.preventDefault();
    if (currentpage !== nPages) {
      setCurrentpage(currentpage + 1);
    }
  }
  function changeCPage(e, id) {
    e.preventDefault();
    setCurrentpage(id);
  }
  ring2.register();
  return (
    <div className="  ">
      <SearchBar />
      <div className="flex flex-col pb-20">
        <div className="lg:w-[60rem] xl:w-[80rem] w-[10rem] mx-auto pl-7 sm:pl-0 mb-4">
          <h1 className="font-bold font-poppins text-2xl mt-3 text-left text-white ">
            Assests
          </h1>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center">
            <span>
              <l-ring-2
                size="40"
                stroke="5"
                stroke-length="0.25"
                bg-opacity="0.1"
                speed="0.8"
                color="white"
              ></l-ring-2>
            </span>
          </div>
        ) : (
          <div>
            {statsData.length === 0 ? (
              <div className="text-white flex items-center justify-center my-4 text-[20px]">
                Data Not Found
              </div>
            ) : (
              <div className="overflow-x-auto mx-3 sm:mx-4 rounded-3xl">
                <div className="max-h-[400px] md:max-h-[70rem] mx-auto lg:w-[60rem] xl:w-[80rem] w-full overflow-y-auto mb-12">
                  <table className="items-center lg:mx-auto w-full lg:w-[60rem] xl:w-[80rem] justify-center rounded-3xl text-left mx-auto">
                    <thead className="bg-[#0F2434] rounded-lg text-white text-[13px] sm:text-[16px] sticky top-0 z-10">
                      <tr className="text-white rounded-xl">
                        <th className="text-left pl-7 py-4 rounded-tl-3xl">
                          {" "}
                          Name{" "}
                        </th>
                        <th className="text-left pl-12 py-4">Contact Adress</th>
                        <th className="text-left pl-7 py-4 rounded-tr-3xl">
                          Total Supply
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-[#071120] ">
                      {records.map((arr) => (
                        <tr
                          key={arr.address}
                          className="text-white bg-[#071120] border-b-[1px] border-[#0F2434]  text-[10px] sm:text-[16px]"
                        >
                          <td className="pl-7 text-left py-3">{arr.name}</td>
                          <td className="pl-12 text-left py-4 sm:py-3 flex items-center text-[#0E83DB]">
                            <div data-tooltip-id={arr.address}>
                              {arr.address.slice(0, 17)}...
                            </div>
                            <span className="pl-2">
                              <IoCopyOutline
                                className="hover:cursor-pointer"
                                onClick={() => copyText1(arr)}
                              />
                            </span>
                            <ReactTooltip
                              id={arr.address}
                              place="top"
                              className="z-40"
                              // variant="danger"
                              style={{
                                backgroundColor: "#040F1C",
                                color: "#0E83DB",
                              }}
                              content={arr.address}
                            />
                          </td>
                          <td className="pl-7 text-left py-3">
                            {arr.total_supply.slice(0, 10)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="sticky bottom-0 left-0 right-0">
                    <ul className="text-white mx-auto w-full flex flex-row justify-center py-3 bg-[#071120] ">
                      <Pagination
                        nextPage={nextPage}
                        changeCPage={changeCPage}
                        prePage={prePage}
                        numbers={numbers}
                        currentpage={currentpage}
                      />
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <ErrorModal
          show={showModal}
          title="Error"
          message={errorMessage}
          handleCloseModal={handleCloseModal}
        />
      </div>
    </div>
  );
};

export default Assests;
