import React from "react";
import { useState, useEffect } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoCopyOutline } from "react-icons/io5";
import Pagination from "../components/Pagination.jsx";
import { ring2 } from "ldrs";

const BlockData = ({
  changeCPage,
  copyText,
  nextPage,
  records,
  numbers,
  prePage,
  currentpage,
  isLoading,
}) => {
  ring2.register();

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center mt-4">
          <span>
            <l-ring-2
              size="40"
              stroke="5"
              stroke-length="0.25"
              bg-opacity="0.1"
              speed="0.8"
              color="white"
            ></l-ring-2>
          </span>
        </div>
      ) : (
        <div>
          {records.length === 0 ? (
            <div className="text-white flex items-center justify-center my-4 text-[20px]">
              Data Not Found
            </div>
          ) : (
            <div>
              <div className="overflow-x-auto mx-3 sm:mx-4 rounded-3xl">
                <div className="max-h-[500px] md:max-h-[70rem] overflow-y-auto mb-12 mx-auto lg:w-[60rem] xl:w-[80rem] rounded-3xl text-left w-full">
                  <table className="items-center mx-auto lg:w-[60rem] xl:w-[80rem] rounded-lg text-left w-full">
                    <thead className="bg-[#0F2434] rounded-lg text-white text-lg sticky top-0 z-10">
                      <tr className="text-white rounded-3xl text-[10px] sm:text-[14px] md:text-[16px]">
                        <th className="sm:text-left sm:pl-7 text-[12px] sm:text-[14px] lg:text-[17px] py-4 px-4 rounded-tl-3xl">
                          {" "}
                          Block{" "}
                        </th>
                        <th className="sm:text-left sm:pl-7 py-4 text-[12px] sm:text-[14px] lg:text-[17px] px-4">
                          Time stamp
                        </th>
                        <th className="sm:text-left sm:pl-7 py-4 text-[12px] sm:text-[14px] lg:text-[17px] px-4">
                          Block Hash
                        </th>
                        <th className="sm:text-left sm:pl-7 py-4 text-[12px] sm:text-[14px] lg:text-[17px] px-4">
                          Validator
                        </th>
                        <th className="sm:text-left sm:pl-7 py-4 text-[12px] sm:text-[14px] lg:text-[17px] px-4">
                          Transaction Count
                        </th>
                        <th className="sm:text-left sm:pl-7 py-4 rounded-tr-3xl text-[12px] sm:text-[14px] lg:text-[17px] px-4">
                          Gas Used
                        </th>
                      </tr>
                    </thead>
                    <tbody className="overflow-y-auto h-[200px]">
                      {records.map((arr) => (
                        <tr
                          key={arr.hash}
                          className="text-white bg-[#071120] border-b-[1px] border-[#0F2434] font-poppins font-semibold text-[10px] sm:text-[12px] md:text-[16px]"
                        >
                          <td className="pl-7 text-left py-3 text-[#1283D7]">
                            {arr?.size}
                          </td>
                          <td className="pl-7 text-left py-3">
                            {arr.timestamp?.slice(12, 13)}m ago
                            <span className="block font-semibold text-[12px] text-[#9CA0A7]">
                              {arr.timestamp.slice(0, 10)}{" "}
                              {arr.timestamp?.slice(11, 15)}
                            </span>{" "}
                          </td>
                          <td
                            data-tooltip-id={arr.hash}
                            className="flex items-center pl-7 text-left pt-8 sm:pt-[2rem] md:pt-[1.5rem] lg:pt-4 md:mt-2"
                          >
                            {arr?.hash?.slice(0, 12)}{" "}
                            <span className="inline-block pl-2">
                              <IoCopyOutline
                                className="hover:cursor-pointer"
                                onClick={() => copyText(arr)}
                              />
                            </span>{" "}
                            <ReactTooltip
                              id={arr?.hash}
                              place="top"
                              className=""
                              // variant="danger"
                              style={{
                                backgroundColor: "#040F1C",
                                color: "white",
                              }}
                              content={arr?.hash}
                            />
                          </td>
                          <td
                            data-tooltip-id={arr.parent_hash}
                            className="pl-7 text-left py-3"
                          >
                            {arr?.parent_hash.slice(0, 12)}
                            <ReactTooltip
                              id={arr?.parent_hash}
                              place="top"
                              className=""
                              // variant="danger"
                              style={{
                                backgroundColor: "#040F1C",
                                color: "white",
                              }}
                              content={arr.parent_hash}
                            />
                          </td>
                          <td className="pl-7 text-left py-3">
                            {arr?.tx_count}
                          </td>
                          <td className="pl-7 pr-2 text-left py-3">
                            {arr.gas_used}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="sticky bottom-0 top-0 left-0 ">
                    <ul className="text-white items-center mx-auto w-full flex flex-row h-7 py-7 justify-center bg-[#071120]">
                      <Pagination
                        nextPage={nextPage}
                        changeCPage={changeCPage}
                        prePage={prePage}
                        numbers={numbers}
                        currentpage={currentpage}
                      />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
// };

export default BlockData;
