import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import SearchBar from "../components/SearchBar";
import Searchres from "../components/Searchres";

const Searchresult = () => {
  // const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  // Check if searchResults exist in state or default to an empty array
  const { searchResults, isLoading } = location.state || {
    searchResults: null,
  };
  // console.log(searchResults, "i am in searchresult");
  return (
    <div>
      {/* Reuse SearchBar */}
      <SearchBar />
      <div>
        <Searchres searchResults={searchResults} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default Searchresult;
