import React, { useState } from "react";
import { IoCopyOutline } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GetDeployAdress = ({ show, title, message, info, handleCloseModal }) => {
  if (!show) return null;

  const notify = () => {
    toast("Address Copied", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progressStyle: { background: "#0061f2" },
      theme: "info",
    });
  };

  const handleCopyText = () => {
    if (info) {
      navigator.clipboard.writeText(info);
    }
    notify();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 z-50">
      <div className="w-[40rem] max-w-md p-6 bg-white rounded-lg shadow-lg">
        <h2 className="mb-4 text-xl font-semibold">{title}</h2>
        <p className="mb-4">{message}</p>
        <div className="flex">
          <p className="pr-4">{info.substring(0, 20)}.....</p>
          <span>{info.substring(34, 42)}</span>
          <button className="" onClick={handleCopyText}>
            <IoCopyOutline className="hover:cursor-pointer" />
          </button>
        </div>
        <div className="flex justify-between">
          <button
            //   onClick={}
            className="px-4 py-2 mt-4 text-white bg-green-500 rounded hover:bg-green-700"
          >
            SwitchNetwork
          </button>
          <button
            onClick={handleCloseModal}
            className="px-4 py-2 mt-4 text-white bg-blue-500 rounded hover:bg-blue-700"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default GetDeployAdress;
