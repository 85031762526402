import "./App.css";
import Navbar from "./components/Navbar";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard.jsx";
import Blocks from "./pages/Blocks.jsx";
import Contracts from "./pages/Contracts.jsx";
import Stats_Graphs from "./pages/Stats & Graphs.jsx";
import Transactions from "./pages/Transactions.jsx";
import Faucet from "./pages/Faucet.jsx";
import Assests from "./pages/Assests.jsx";
import Searchresult from "./pages/Searchresult.jsx";
import Cardresultcomponent from "./pages/Cardresultcomponent.jsx";
import Createtoken from "./pages/Multistepform.jsx";
import Searchres from "./components/Searchres.jsx";
import SearchBlock from "./components/SearchBlock.jsx";
import Searchhash from "./components/Searchhash.jsx";

function App() {
  return (
    <div className="App">
      <Navbar />

      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/blocks" element={<Blocks />} />
        <Route path="/contracts" element={<Contracts />} />
        <Route path="/assests" element={<Assests />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/stats" element={<Stats_Graphs />} />
        <Route path="/faucet" element={<Faucet />} />
        <Route path="/searchresult" element={<Searchresult />} />
        <Route path="/searchresult" element={<Searchres />} />
        <Route path="/searchresult/searchblock" element={<SearchBlock />} />
        <Route path="/searchresult/searchhash" element={<Searchhash />} />
        <Route path="/Cardresultcomponent" element={<Cardresultcomponent />} />
        <Route path="/createtoken" element={<Createtoken />} />
      </Routes>
    </div>
  );
}

export default App;
