import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Cardresultcarousel from "./Cardresultcarousel";
import Cardresultcomponent from "../pages/Cardresultcomponent";
import { Navigate } from "react-router-dom";
import { fetchData } from "../api";
import ErrorModal from "./ErrorModal";

const Cardresult = ({ mod, setMod }) => {
  const [statsData, setStatsData] = useState([]);
  const [error, setError] = useState(null);
  const [modelopen, setModelopen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleError = (error) => {
    setErrorMessage(error);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setErrorMessage(null);
  };
  const test = async () => {
    try {
      const res = await fetchData(
        `${process.env.REACT_APP_GNOSIS_TRANSACTIONS}`
      );
      setStatsData(res);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError("Something went wrong. Please try later.");
      console.error("Error caught in loadData:", error.message);
    }
  };

  useEffect(() => {
    test();
  }, []);

  const toggleModal = () => {
    setModelopen(!modelopen);
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    largedesktop: {
      breakpoint: { max: 3000, min: 1280 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1280, min: 1023 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1023, min: 762 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 762, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
  };
  return (
    <div className="mb-10 ">
      <div className="flex justify-between items-center px-3 flex-col sm:flex-row sm:px-1 w-[16rem] md:w-[40rem] lg:w-[60rem] xl:w-[80rem] mx-auto my-3">
        <h1 className="font-bold text-[14px] md:text-[24px] text-white w-[12rem] sm:w-[18rem]  uppercase">
          Recent Transactions
        </h1>
        <button
          className="rounded-xl h-[35px] my-4 sm:my-0 sm:h-[42px] px-4 sm:px-3 sm:py-2 text-white bg-blue-600 text-[10px] md:text-[14px]"
          onClick={toggleModal}
        >
          View All
        </button>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center">
          <span>
            <l-ring-2
              size="40"
              stroke="5"
              stroke-length="0.25"
              bg-opacity="0.1"
              speed="0.8"
              color="white"
            ></l-ring-2>
          </span>
        </div>
      ) : (
        <div>
          {statsData.length === 0 ? (
            <div className="text-white flex items-center justify-center my-4 text-[20px]">
              Data Not Found
            </div>
          ) : (
            <div className="w-[325px] mx-auto sm:mx-auto md:w-[700px] lg:w-[970px] xl:w-[1280px] md:mx-6 lg:mx-auto xl:mx-auto">
              <Carousel
                swipeable={false}
                draggable={true}
                showDots={false}
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={1000}
                arrows={false}
                partialVisible={true}
              >
                {/* Map over your data and render multiple instances of Carddetailscarousel */}
                {statsData.map((stats) => (
                  <Cardresultcarousel stats={stats} key={stats.hash} />
                ))}
              </Carousel>
            </div>
          )}
        </div>
      )}
      {modelopen && (
        <Navigate
          to="/Cardresultcomponent"
          statsData={statsData}
          isLoading={isLoading}
        /> // Pass onClose prop to handle modal close
      )}
      <ErrorModal
        show={showModal}
        title="Error"
        message={errorMessage}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
};

export default Cardresult;
