import React, { useEffect, useMemo, useState } from "react";
import SearchBar from "../components/SearchBar.jsx";
import BlockCards from "../components/BlockCards.jsx";
import BlockData from "../components/BlockData.jsx";
import { fetchData } from "../api.js";
import ErrorModal from "../components/ErrorModal.jsx";

const Blocks = () => {
  const [currentpage, setCurrentpage] = useState(1);
  const [statsData, setStatsData] = useState([]);
  const [singleItem, setSingleItem] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const handleError = (error) => {
    setErrorMessage(error);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setErrorMessage(null);
  };
  const test = async () => {
    try {
      const res = await fetchData(`${process.env.REACT_APP_GNOSIS_BLOCK}`);

      const sortedData = [...res.items].sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );
      setStatsData(sortedData);
      setSingleItem(sortedData[0]);
      setIsLoading(false);
      // setSingleItem(statsData[0]);
    } catch (error) {
      setIsLoading(false);
      handleError("Something went wrong. Please try again later");
      console.error("Error caught in loadData:", error.message);
    }
  };
  useEffect(() => {
    test();
  }, []);

  const recordsperpage = 11;
  const indexoflastrecord = currentpage * recordsperpage;
  const indexoffirstrecord = indexoflastrecord - recordsperpage;
  const records = statsData.slice(indexoffirstrecord, indexoflastrecord);
  const nPages = Math.ceil(statsData?.length / recordsperpage);
  const numbers = [...Array(nPages + 1).keys()].slice(1);

  function prePage(e) {
    e.preventDefault();
    if (currentpage !== 1) {
      setCurrentpage(currentpage - 1);
    }
  }
  function nextPage(e) {
    e.preventDefault();
    if (currentpage !== nPages) {
      setCurrentpage(currentpage + 1);
    }
  }
  function changeCPage(e, id) {
    e.preventDefault();
    setCurrentpage(id);
  }
  function copyText(arr) {
    /* Copy text into clipboard */
    navigator.clipboard.writeText(arr?.hash);
  }
  return (
    <div>
      <SearchBar />
      <BlockCards singleItem={singleItem} />
      <BlockData
        changeCPage={changeCPage}
        copyText={copyText}
        nextPage={nextPage}
        records={records}
        numbers={numbers}
        prePage={prePage}
        currentpage={currentpage}
        isLoading={isLoading}
      />
      <ErrorModal
        show={showModal}
        title="Error"
        message={errorMessage}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
};

export default Blocks;
