import React from "react";
import DynamicChart from "./DynamicChart";

const TransactionChart = ({ singleItem, userData }) => {
  return (
    <div>
      <div className="bg-[#0F2434] flex flex-col lg:flex-row xl:flex-row rounded-xl shadow-lg w-[23rem] sm:w-full md:w-[47rem] lg:w-[60rem] xl:w-[80rem] h-[28rem] items-center mx-auto  my-5">
        <div className="justify-center  text-white flex lg:flex-col mx-8">
          <div className="px-4 text-center lg:text-left">
            <h1 className="my-3 text-[#9CA0A7] text-[12px]">
              Total Transactions
            </h1>
            <p className="text-2xl mb-2">
              {(singleItem && (singleItem?.block / 10000000).toFixed(2)) || 0}
            </p>
          </div>
          <div className="px-1 text-center lg:text-left">
            <h1 className="my-3 text-[#9CA0A7] text-[12px]">
              Contract Transaction
            </h1>
            <p className="text-2xl mb-2">
              {(singleItem && singleItem?.confirmations) || 0}
            </p>
          </div>
          {/* <div className="px-1 text-center lg:text-left">
            <h1 className="my-3 text-[#9CA0A7]">Gas Fee Paid</h1>
            <p className="text-2xl mb-2">{(singleItem?.gas_price/100000000000).toFixed(3)}</p>
          </div> */}
        </div>
        <div>
          <DynamicChart userData={userData} className="" />
        </div>
      </div>
    </div>
  );
};

export default TransactionChart;
