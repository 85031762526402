import React from "react";
import Cards from "../components/Cards";
import Hero from "../components/Hero.jsx";
import Cardresult from "../components/Cardresult.jsx";
import Footer from "../components/Footer.jsx";
import SearchBar from "../components/SearchBar.jsx";
import { useState, useEffect } from "react";
import mainpage from "../assests/videos/mainpage.mp4";
import block from "../assests/pngs/block.png";
import BlockData from "../components/BlockData.jsx";
import { fetchData } from "../api.js";
import ErrorModal from "../components/ErrorModal.jsx";

const Home = () => {
  const [currentpage, setCurrentpage] = useState(1);
  const [statsData, setStatsData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLandscape, setIsLandscape] = useState(
    window.orientation === 90 || window.orientation === -90
  );

  const handleError = (error) => {
    setErrorMessage(error);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  const test = async () => {
    try {
      const res = await fetchData(`${process.env.REACT_APP_GNOSIS_BLOCK}`);
      const sortedData = [...res.items].sort((a, b) => {
        return new Date(b.timestamp) - new Date(a.timestamp);
      });
      setStatsData(sortedData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError("Something went wrong. Please try again later");
      console.error("Error caught in loadData:", error.message);
    }
  };

  useEffect(() => {
    test();
  }, [statsData]);

  const recordsperpage = 11;
  const indexoflastrecord = currentpage * recordsperpage;
  const indexoffirstrecord = indexoflastrecord - recordsperpage;
  const records = statsData.slice(indexoffirstrecord, indexoflastrecord);
  const nPages = Math.ceil(statsData?.length / recordsperpage);
  const numbers = [...Array(nPages + 1).keys()].slice(1);

  function prePage(e) {
    e.preventDefault();
    if (currentpage !== 1) {
      setCurrentpage(currentpage - 1);
    }
  }

  function nextPage(e) {
    e.preventDefault();
    if (currentpage !== nPages) {
      setCurrentpage(currentpage + 1);
    }
  }

  function changeCPage(e, id) {
    e.preventDefault();
    setCurrentpage(id);
  }

  function copyText(arr) {
    /* Copy text into clipboard */
    navigator.clipboard.writeText(arr?.hash);
  }

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsLandscape(window.orientation === 90 || window.orientation === -90);
    };

    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  return (
    <div>
      <div>
        {!isLandscape ? (
          <video
            src={mainpage}
            autoPlay
            loop
            preload="auto"
            muted
            className="w-full h-[100vh] object-cover"
          ></video>
        ) : (
          <video
            src={mainpage}
            autoPlay
            loop
            preload="auto"
            muted
            className="w-full h-[200vh] sm:h-[150vh] md:h-[155vh] lg:h-[100vh] object-cover relative"
          ></video>
        )}
        <div className="absolute top-16 sm:top-28 md:top-32 w-[100%] h-[100%] ">
          <SearchBar />
          <Cards />
        </div>
      </div>
      <div>
        <Hero />
        {/* <Carddetails mod={mod} setMod={setMod} /> */}
        <Cardresult />
        <div>
          <div className="w-[16rem] md:w-[40rem] lg:w-[60rem] xl:w-[80rem] mx-auto mb-4 ">
            <h1 className="flex items-center w-[4rem] ml-4">
              <img src={block} className="w-7" alt="" />
              <span className="ml-3 text-white text-[21px] sm:text-2xl font-bold">
                Blocks
              </span>
            </h1>
          </div>
          <BlockData
            changeCPage={changeCPage}
            copyText={copyText}
            nextPage={nextPage}
            records={records}
            numbers={numbers}
            prePage={prePage}
            currentpage={currentpage}
            isLoading={isLoading}
          />
          <ErrorModal
            show={showModal}
            title="Error"
            message={errorMessage}
            handleCloseModal={handleCloseModal}
          />
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Home;
