import React, { useState, useEffect } from "react";
import Charts from "./Charts";

const Hero = () => {
  const [userData, setUserData] = useState({
    labels: [],
    datasets: [
      {
        label: "User Gained",
        data: [],
        backgroundColor: ["#0C71BC", "#5DAFEB"],
      },
    ],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_GNOSIS_STATS_GRAPHS}`);
        const { chart_data } = await res.json();

        // Sort the data in ascending order (oldest to newest)
        const sortedData = chart_data.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );

        // Slice the last 12 entries (latest 12 dates in ascending order)
        const latestData = sortedData.slice(-12); // Extracts the last 12 records

        // Extract the day part of the date for labels and transaction counts for data
        const latestDates = latestData.map((item) => item.date?.substring(8)); // Extracting day part
        const txCounts = latestData.map((item) => item.tx_count / 1000); // Normalizing tx_count

        // Update chart data with the sorted latest dates and corresponding transaction counts
        setUserData((prevUserData) => ({
          ...prevUserData,
          labels: latestDates,
          datasets: [
            {
              ...prevUserData.datasets[0],
              data: txCounts,
            },
          ],
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Charts chartData={userData} />
    </div>
  );
};

export default Hero;
