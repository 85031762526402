
// // This is the new code

// import React, {
//   useState,
//   useEffect,
//   useContext,
//   useRef,
//   createContext,
// } from "react";
// import { useConnectWallet } from "@web3-onboard/react";
// import { useBlockchainDeployToken } from "../service/DeployTokenService";
// import { BrowserProvider } from "ethers";
// import ErrorModal from "../components/ErrorModal";

// // Create the MetaMask context
// const MetaMaskContext = createContext();

// export const MetaMaskProvider = ({ children }) => {
//   const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
//   const  {getAllDeployedTokens, switchToIdeaTestnet}  =
//     useBlockchainDeployToken(wallet);

//   const [errorMessage, setErrorMessage] = useState("");
//   const [showModal, setShowModal] = useState(false);
//   const [address, setAddress] = useState("");
//   const [isConnecting, setIsConnecting] = useState(false);
//   const ethersProviderRef = useRef();

//   useEffect(() => {
//     if (wallet) {
//       const walletAddress = wallet?.accounts[0]?.address || "";
//       setAddress(walletAddress);
//       if (!ethersProviderRef.current) {
//         ethersProviderRef.current = new BrowserProvider(wallet.provider);
//       }

//       // Check the network only after the wallet is connected
//       checkNetwork();
//     }
//   }, [wallet]);

//   const checkNetwork = async () => {
//     try {
//       if (wallet && window.ethereum) {
//         const chainId = await window.ethereum.request({
//           method: "eth_chainId",
//         });
//         console.log("Current Chain ID:", chainId);

//         if (chainId !== "0xb90") {
//           console.log("Switching to IDEA Testnet...");
//           await switchToIdeaTestnet(); // Attempt to switch to IDEA Testnet
//           const newChainId = await window.ethereum.request({
//             method: "eth_chainId",
//           });
//           console.log("New Chain ID after switch:", newChainId);

//           if (newChainId !== "0xb90") {
//             throw new Error("Failed to switch to the IDEA Testnet");
//           }
//         } else {
//           console.log("Already on IDEA Mainnet or Testnet");
//         }
//       }
//     } catch (error) {
//       handleError("Network check failed: " + error.message);
//       disconnectWallet(); // Disconnect if the network check fails
//       throw error;
//     }
//   };

//   const handleError = (error) => {
//     setErrorMessage(error);
//     setShowModal(true);
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//     setErrorMessage(null);
//   };

//   const connectWallet = async () => {
//     setIsConnecting(true);
//     try {
//       await connect();
//       await checkNetwork(); // Check the network immediately after connecting
//     } catch (error) {
//       handleError("Failed to connect wallet: " + error.message);
//       disconnectWallet();
//     } finally {
//       setIsConnecting(false);
//     }
//   };

//   const copyText = () => {
//     if (address) {
//       navigator.clipboard.writeText(address);
//     }
//   };

//   const handleGetAllDeployedTokens = async () => {
//     try {
//       const tokens = await getAllDeployedTokens();
//       console.log("Deployed tokens:", tokens);
//     } catch (error) {
//       handleError("Error fetching deployed tokens");
//     }
//   };

//   const disconnectWallet = async () => {
//     try {
//       if (wallet) {
//         await disconnect(wallet);
//         setAddress(""); // Clear the address from state
//         console.log("Wallet disconnected successfully");
//       } else {
//         console.log("No wallet is currently connected");
//       }
//     } catch (error) {
//       handleError("Failed to disconnect wallet");
//     }
//   };

//   const value = {
//     address,
//     connectWallet,
//     disconnectWallet,
//     copyText,
//     connecting: isConnecting || connecting, // Track connecting state
//     handleGetAllDeployedTokens,
//     ethersProvider: ethersProviderRef.current,
//   };

//   return (
//     <MetaMaskContext.Provider value={value}>
//       {showModal && (
//         <ErrorModal
//           show={showModal}
//           title="Network Error"
//           message={errorMessage}
//           handleCloseModal={handleCloseModal}
//         />
//       )}
//       {children}
//     </MetaMaskContext.Provider>
//   );
// };

// // Custom hook to use MetaMask context
// export const useMetaMask = () => {
//   const context = useContext(MetaMaskContext);
//   if (!context) {
//     throw new Error("useMetaMask must be used within a MetaMaskProvider");
//   }
//   return context;
// };

// this is the most suitable code right now

import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  createContext,
} from "react";
import { useConnectWallet } from "@web3-onboard/react";
import { useBlockchainDeployToken } from "../service/DeployTokenService";
import { BrowserProvider } from "ethers";
import ErrorModal from "../components/ErrorModal";

// Create the MetaMask context
const MetaMaskContext = createContext();

export const MetaMaskProvider = ({ children }) => {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const { getAllDeployedTokens, switchToIdeaTestnet } =
    useBlockchainDeployToken(wallet);

  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [address, setAddress] = useState("");
  const [isConnecting, setIsConnecting] = useState(false);
  const ethersProviderRef = useRef();

  useEffect(() => {
    if (wallet) {
      const walletAddress = wallet?.accounts[0]?.address || "";
      setAddress(walletAddress);
      if (!ethersProviderRef.current) {
        ethersProviderRef.current = new BrowserProvider(wallet.provider);
      }

      // Check the network only after the wallet is connected
      checkNetwork();
    }
  }, [wallet]);

  const checkNetwork = async () => {
    try {
      if (wallet && window.ethereum) {
        const chainId = await window.ethereum.request({
          method: "eth_chainId",
        });
        console.log("Current Chain ID:", chainId);

        if (chainId !== "0xb90") {
          console.log("Switching to IDEA Testnet...");
          await switchToIdeaTestnet(); // Attempt to switch to IDEA Testnet
          const newChainId = await window.ethereum.request({
            method: "eth_chainId",
          });
          console.log("New Chain ID after switch:", newChainId);

          if (newChainId !== "0xb90") {
            throw new Error("Failed to switch to the IDEA Testnet");
          }
        } else {
          console.log("Already on IDEA Mainnet or Testnet");
        }
      }
    } catch (error) {
      console.error("Network check failed: " + error.message);
      await disconnectWallet(); // Disconnect if the network check fails
      handleError(error.message); // Show error modal only if the user fails to switch
    }
  };

  const handleError = (error) => {
    setErrorMessage(error);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  const connectWallet = async () => {
    setIsConnecting(true);
    try {
      await connect();
      await checkNetwork(); // Check the network immediately after connecting
    } catch (error) {
      handleError("Failed to connect wallet: " + error.message);
    } finally {
      setIsConnecting(false);
    }
  };

  const copyText = () => {
    if (address) {
      navigator.clipboard.writeText(address);
    }
  };

  const handleGetAllDeployedTokens = async () => {
    try {
      const tokens = await getAllDeployedTokens();
      console.log("Deployed tokens:", tokens);
    } catch (error) {
      handleError("Error fetching deployed tokens");
    }
  };

  const disconnectWallet = async () => {
    try {
      if (wallet) {
        await disconnect(wallet);
        setAddress(""); // Clear the address from state
        console.log("Wallet disconnected successfully");
      } else {
        console.log("No wallet is currently connected");
      }
    } catch (error) {
      handleError("Failed to disconnect wallet");
    }
  };

  const value = {
    address,
    connectWallet,
    disconnectWallet,
    copyText,
    connecting: isConnecting || connecting, // Track connecting state
    handleGetAllDeployedTokens,
    ethersProvider: ethersProviderRef.current,
  };

  return (
    <MetaMaskContext.Provider value={value}>
      {showModal && (
        <ErrorModal
          show={showModal}
          title="Network Error"
          message={errorMessage}
          handleCloseModal={handleCloseModal}
        />
      )}
      {children}
    </MetaMaskContext.Provider>
  );
};

// Custom hook to use MetaMask context
export const useMetaMask = () => {
  const context = useContext(MetaMaskContext);
  if (!context) {
    throw new Error("useMetaMask must be used within a MetaMaskProvider");
  }
  return context;
};
