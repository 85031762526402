import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Web3OnboardProvider } from "@web3-onboard/react";
import { web3Onboard } from "./service/index";
import { MetaMaskProvider } from "./ContextApi/MetaMaskProvider";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Web3OnboardProvider web3Onboard={web3Onboard}>
      <MetaMaskProvider>
        <App />
      </MetaMaskProvider>
    </Web3OnboardProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
