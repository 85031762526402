import React from "react";
import { IoCopyOutline } from "react-icons/io5";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { ring2 } from "ldrs";

const TransactionData = ({ statsData, copyText, copyText1, isLoading }) => {
  ring2.register();

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center my-4">
          <span>
            <l-ring-2
              size="40"
              stroke="5"
              stroke-length="0.25"
              bg-opacity="0.1"
              speed="0.8"
              color="white"
            ></l-ring-2>
          </span>
        </div>
      ) : (
        <div>
          {statsData.length === 0 ? (
            <div className="text-white flex items-center justify-center my-4 text-[20px]">
              Data Not Found
            </div>
          ) : (
            <div className="rounded-3xl mb-20 mx-3 mt-4">
              <div className=" overflow-x-auto  rounded-3xl">
                <table className="items-center mx-auto w-[80rem] rounded-3xl text-left">
                  <thead className="bg-[#0F2434] rounded-lg text-white font-bold font-poppins ">
                    <tr className="text-white rounded-xl">
                      <th className="text-left pl-7 py-4 rounded-tl-3xl">
                        {" "}
                        TxHash{" "}
                      </th>
                      <th className="text-left pl-7 py-4">Block</th>
                      <th className="text-left pl-7 py-4">Method</th>
                      <th className="text-left pl-7 py-4">From</th>
                      <th className="text-left pl-7 py-4">To</th>
                      <th className="text-left pl-7 py-4">Timestamp</th>
                      <th className="text-left pl-7 py-4">Value</th>
                      <th className="text-left pl-7 py-4 rounded-tr-3xl">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {statsData.map((arr) => (
                      <tr
                        key={arr?.hash}
                        className="text-white bg-[#071120] border-b-[1px] border-[#0F2434] font-poppins font-bold text-[14px] "
                      >
                        <td className="pl-7 text-left py-3">
                          <span data-tooltip-id={arr?.hash}>
                            {arr?.hash?.slice(0, 14)}...
                          </span>
                          <ReactTooltip
                            id={arr?.hash}
                            place="top"
                            className=""
                            // variant="danger"
                            style={{
                              backgroundColor: "#040F1C",
                              color: "white",
                            }}
                            content={arr?.hash}
                          />
                        </td>
                        <td className="pl-7 text-left py-3 text-[#0E83DB] ">
                          {arr.block}
                        </td>
                        <td className="pl-7 text-left py-3 ">Coin_Transfer</td>
                        {/* {String(arr.method).slice(0,8)} */}
                        <td className="pl-7 text-left py-3 flex items-center mt-2 text-[#0E83DB]">
                          <span data-tooltip-id={arr?.from?.hash}>
                            {arr?.from?.hash.slice(0, 14)}...{" "}
                          </span>
                          <ReactTooltip
                            id={arr?.from?.hash}
                            place="top"
                            className=""
                            // variant="danger"
                            style={{
                              backgroundColor: "#040F1C",
                              color: "#0E83DB",
                            }}
                            content={arr?.from?.hash}
                          />
                          <span className="inline-block pl-2">
                            <IoCopyOutline
                              className="hover:cursor-pointer"
                              onClick={() => copyText1(arr)}
                            />
                          </span>{" "}
                        </td>
                        <td className="pl-7 text-left py-3  text-[#0E83DB]">
                          <span data-tooltip-id={arr?.to?.hash}>
                            {arr.to?.hash.slice(0, 14)}...
                          </span>
                          <span className="inline-block pl-2 ">
                            <IoCopyOutline
                              className="hover:cursor-pointer"
                              onClick={() => copyText(arr)}
                            />
                          </span>{" "}
                          <ReactTooltip
                            id={arr?.to?.hash}
                            place="top"
                            className=""
                            // variant="danger"
                            style={{
                              backgroundColor: "#040F1C",
                              color: "#0E83DB",
                            }}
                            content={arr?.to?.hash}
                          />
                        </td>
                        <td className="pl-7 text-left py-3">
                          {arr.timestamp.slice(11, 13)}m ago
                          <span className="block font-semibold text-[14px] text-[#9CA0A7]">
                            {arr.timestamp.slice(0, 10)}{" "}
                            {arr.timestamp.slice(11, 15)}
                          </span>
                        </td>
                        <td className="pl-7 text-left py-3">
                          {arr.value.slice(0, 4)}
                        </td>
                        <td className="pl-7 text-left py-3">
                          <button className="text-green-500">
                            {arr.result.slice(0, 8)}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
// };

export default TransactionData;
