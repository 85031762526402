import React from "react";
import Taxes from "../components/Taxes";
// import Summary from "../components/Summary";
// import CreateToken from "../components/Tokeninfo";
// import Stepper from "../components/Stepper";
import CreateToken from "../components/Tokeninfo";
const Createtoken = () => {
  return (
    <div className="h-fit bg-gradient-to-r from-[#07131C] to-[#06375b] py-3">
      {/* <Taxes /> */}
      {/* <Tokeninfo /> */}
      <CreateToken />
    </div>
  );
};

export default Createtoken;
