import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Navigate, useNavigate } from "react-router-dom";
import { fetchData } from "../api";
import ErrorModal from "./ErrorModal";

const SearchBar = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [menuopen, setMenuopen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const handleError = (error) => {
    setErrorMessage(error);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  const handleSearch = async () => {
    console.log("I am working in here");
    setIsLoading(true); // Start loading

    try {
      const res = await fetchData(
        `${process.env.REACT_APP_GNOSIS_SEARCH}?q=${query}`
      );

      if (res && Array.isArray(res) && res.length > 0) {
        // If data exists, navigate with search results
        console.log(res, "I am working in here 2");
        navigate("/searchresult", {
          state: { searchResults: res, isLoading: false, message: "" },
        });
      } else {
        // If no data, pass a message to the search result page
        navigate("/searchresult", {
          state: { searchResults: [], message: "There is nothing to display" },
        });
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try again.");
      setShowModal(true); // Show error modal if something goes wrong
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };
  return (
    <div className="font-poppins">
      <div className="flex items-center justify-center mt-7">
        <div className="relative">
          <FaSearch className="text-white absolute hidden sm:block mt-[12px] sm:mt-[25px] ml-0 sm:ml-4" />
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search for any address, transaction or block"
            className="border border-[#CDDFE5] border-opacity-[0.1] mb-2 h-[40px] sm:h-[65px] w-[20rem] sm:w-[600px] pl-4 sm:pl-11 pr-[5.5rem] sm:pr-32 md:pr-[8rem] lg:pr-32 py-3 sm:py-6 bg-[#081015]  bg-opacity-[0.8] text-white font-poppins tracking-wide z-[-8] font-normal md:font-semibold rounded-xl stroke-black outline-none placeholder:-translate-y-0.5 sm:placeholder:-translate-y-0 placeholder:text-[12px] sm:placeholder:text-[15px] sm:placeholder-white "
          />
          <button
            className="text-white font-bold text-[14px] sm:text-[18px] h-[22px] sm:h-[48px] border-transparent rounded-lg my-[0.5rem] sm:py-[0.5rem] mr-3 sm:mr-5 sm:px-3 px-1 w-16 sm:w-24 cursor-pointer absolute bg-[#0086FF] hidden sm:block top-0 right-0"
            onClick={() => {
              handleSearch();
              setMenuopen(true);
            }}
          >
            Search
          </button>
          <button
            className=""
            onClick={() => {
              handleSearch();
              setMenuopen(true);
            }}
          >
            <FaSearch className="text-white absolute visible sm:hidden top-[12px] right-4" />
          </button>
        </div>
      </div>
      {/* Render Modal if modalOpen is true */}
      {/* {menuopen && (
        <Navigate to="/searchres" state={{ searchResults, isLoading }} />
      )} */}
      <ErrorModal
        show={showModal}
        title="Error"
        message={errorMessage}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
};

export default SearchBar;
