import React from "react";
import StatChart from "../components/Statchart";
import SearchBar from "../components/SearchBar.jsx";

const Stats_Graphs = () => {


  return (
    <div>
      <SearchBar />
      <StatChart />
    </div>
  );
};

export default Stats_Graphs;
