import { ring2 } from "ldrs";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SearchBar from "./SearchBar";

const SearchBlock = () => {
  ring2.register();
  const location = useLocation();
  const { isLoading, result } = location.state || [];
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(isLoading);
  const [show, setShow] = useState(false);

  // Correctly fetch data without causing an infinite loop
  const fetchDataFromAPI = async () => {
    if (result) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_GNOSIS_BLOCK_SINGLE}/${result}`
        ); // Adjust the URL as necessary
        const fetchedData = await response.json();
        setData(fetchedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Stop loading after the data is fetched
      }
    }
  };
  console.log(data, "i am testing data");

  // Fetch the data on component mount without looping
  useEffect(() => {
    fetchDataFromAPI(); // Fetch data only once on mount
  }, []); // Empty dependency array ensures it runs only once

  const handleClick = () => {
    setShow((previous) => !previous);
  };

  const formatTimestamp = (timestamp) => {
    // Parse the date string
    const date = new Date(timestamp);

    // Define options for formatting
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      timeZone: "UTC",
    };

    // Format date to local string
    const localDate = new Intl.DateTimeFormat("en-US", options).format(date);

    // Get the UTC offset
    const utcOffset = date.getTimezoneOffset() / -60; // Convert minutes to hours
    const offsetString = `(+${String(utcOffset).padStart(2, "0")}:00 UTC)`;

    return `${localDate} ${offsetString}`;
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="">
      <SearchBar />
      <div>
        {loading ? (
          <div className="flex justify-center items-center mt-20">
            <span>
              <l-ring-2
                size="40"
                stroke="5"
                stroke-length="0.25"
                bg-opacity="0.1"
                speed="0.8"
                color="white"
              ></l-ring-2>
            </span>
          </div>
        ) : (
          <div className="text-gray-400 font-Poppins p-4 md:p-6 lg:p-8">
            <div className="max-w-3xl mx-auto">
              <div className="p-6 md:p-8 rounded-lg shadow-md ">
                <h1 className="text-3xl text-white font-medium mb-4">
                  Block #{data?.difficulty}
                </h1>

                <div className="mb-4 flex sm:flex-row flex-col">
                  <span>Mined by</span>
                  <a
                    href="#"
                    className="text-blue-500 pl-0 md:pl-3 text-[14px] md:text-lg"
                  >
                    {data?.miner?.hash}
                  </a>
                </div>

                <div className="space-y-2 md:space-y-4">
                  <div className="flex justify-between flex-col md:flex-row">
                    <span className="text-gray-400">Block height</span>
                    <span className="text-white">
                      {numberWithCommas(data?.difficulty)}
                    </span>
                  </div>
                  <div className="flex justify-between flex-col md:flex-row">
                    <span className="text-gray-400">Size</span>
                    <span className="text-white">
                      {numberWithCommas(data?.size)}
                    </span>
                  </div>
                  <div className="flex justify-between flex-col md:flex-row">
                    <span className="text-gray-400">Timestamp</span>
                    <span className="text-white">
                      {formatTimestamp(data?.timestamp)}
                    </span>
                  </div>
                  <div className="flex justify-between flex-col md:flex-row">
                    <span className="text-gray-400">Transactions</span>
                    <span className="text-white">
                      {data?.tx_count} txns in this block
                    </span>
                  </div>
                  <div className="flex justify-between flex-col md:flex-row">
                    <span className="text-gray-400">Mined By</span>
                    <span className="text-white text-[14px] md:text-lg">
                      {data?.miner?.hash}
                    </span>
                  </div>
                  <div className="flex justify-between flex-col md:flex-row">
                    <span className="text-gray-400">Gas used</span>
                    <span className="text-white">{data?.gas_used}</span>
                  </div>
                  <div className="flex justify-between flex-col md:flex-row">
                    <span className="text-gray-400">Gas limit</span>
                    <span className="text-white">
                      {numberWithCommas(data?.gas_limit)}
                    </span>
                  </div>
                </div>

                <div onClick={handleClick} className="cursor-pointer mt-4">
                  {show ? (
                    <div className="space-y-4">
                      <button className="text-blue-500 ">Hide Details</button>
                      <div className="space-y-2 md:space-y-4">
                        <div className="flex justify-between sm:flex-row flex-col">
                          <span className="text-gray-400">Difficulty</span>
                          <span className="text-white">
                            {numberWithCommas(data?.difficulty)}
                          </span>
                        </div>
                        <div className="flex justify-between sm:flex-row flex-col">
                          <span className="text-gray-400">
                            Total difficulty
                          </span>
                          <span className="text-white">
                            {numberWithCommas(data?.total_difficulty)}
                          </span>
                        </div>
                        <div className="flex justify-between md:flex-row flex-col">
                          <span className="text-gray-400">Hash</span>
                          <div className="flex ">
                            <span className="text-white">
                              {data.hash.slice(0, 25)}.....
                            </span>
                            <span className="text-white">
                              {data.hash.slice(60, 70)}
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between md:flex-row flex-col">
                          <span className="text-gray-400">Parent hash</span>
                          <div className="flex">
                            <span className="text-white">
                              {data?.parent_hash?.slice(0, 20)}.....
                            </span>
                            <span className="text-white">
                              {data?.parent_hash?.slice(55, 66)}
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between sm:flex-row flex-col">
                          <span className="text-gray-400">Nonce</span>
                          <span className="text-white">{data?.nonce}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <button className="text-blue-500">View Details</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBlock;
