import React, { useEffect, useState } from "react";
import { GiBrokenBone } from "react-icons/gi";
import { FaCopy, FaQrcode } from "react-icons/fa";
import axios from "axios";
import { ring2 } from "ldrs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QRCodeSVG } from "qrcode.react";
import Modal from "react-modal";
import SearchBlock from "./SearchBlock";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../api";

// Set the app element for accessibility
Modal.setAppElement("#root");

const Searchres = ({ searchResults, isLoading }) => {
  const navigate = useNavigate();
  ring2.register();
  const notify = () => {
    toast("Address Copied", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progressStyle: { background: "#0061f2" },
      style: { backgroundColor: "black" },
      theme: "info",
    });
  };

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        notify(); // Show the notification when copy is successful
      })
      .catch((err) => {
        console.error("Failed to copy: ", err); // Handle errors if any
      });
  };

  const [selectedData, setSelectedData] = useState(null); // State to store detailed data
  const [isDetailLoading, setIsDetailLoading] = useState(false); // Loader for detailed data
  const [detailError, setDetailError] = useState(null); // Handle errors
  const [selectedToken, setSelectedToken] = useState(null); // To store which token was clicked
  const [modalIsOpen, setModalIsOpen] = useState(false); // State for the modal
  const [statsData, setStatsData] = useState([]);

  useEffect(() => {
    setSelectedData(null);
    setSelectedToken(null);
    setIsDetailLoading(false);
    setDetailError(null);
  }, [searchResults]);
  // Function to fetch detailed data when a result is clicked
  const fetchDetails = async (tokenAddress) => {
    setSelectedToken(tokenAddress); // Mark the clicked token
    setIsDetailLoading(true); // Start loading for detail data
    setDetailError(null); // Reset previous errors

    try {
      const [detailsResponse, countersResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_ADDRESS}/${tokenAddress}`),
        axios.get(`${process.env.REACT_APP_ADDRESS}/${tokenAddress}/counters`),
      ]);

      console.log(detailsResponse, "this is detailsResponse");
      console.log(countersResponse, "this is countersResponse");

      setSelectedData({
        name: detailsResponse.data.name || "Address Detail",
        symbol: detailsResponse?.data?.token?.symbol || "",
        contractAddress: detailsResponse.data.hash, // Store the token address explicitly
        total_supply: detailsResponse?.data?.token?.total_supply || "",
        balance: detailsResponse.data.coin_balance || 0,
        transactions: countersResponse.data.transactions_count || 0,
        gasused: countersResponse?.data?.gas_usage_count || "",
        holders: detailsResponse?.data?.token?.holders || "",
        transfersCount: countersResponse?.data?.transfers_count || "",
        decimals: detailsResponse?.data?.token?.decimals || "",
        lastupdate:
          detailsResponse?.data?.block_number_balance_updated_at || "0",
        transactionsCount: countersResponse.data.transactionsCount || "N/A",
      });
    } catch (error) {
      setDetailError("Unable to fetch data. Please try again later.");
    } finally {
      setIsDetailLoading(false); // Stop loading
    }
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleCLick = async () => {
    try {
      const res = await fetchData(`${process.env.REACT_APP_GNOSIS_BLOCK}`);
      setStatsData(res);
      // setSingleItem(sortedData[0]);
    } catch {
      // handleError("Something went wrong. Please try again later");
      console.log("Error caught in loadData:");
    }
  };

  useEffect(() => {
    handleCLick();
  }, [statsData]);

  return (
    <div>
      <ToastContainer />
      {isLoading ? (
        <div className="flex justify-center items-center mt-20">
          <span>
            <l-ring-2
              size="40"
              stroke="5"
              stroke-length="0.25"
              bg-opacity="0.1"
              speed="0.8"
              color="white"
            ></l-ring-2>
          </span>
        </div>
      ) : selectedToken ? (
        isDetailLoading ? (
          <div className="flex justify-center items-center mt-20">
            <span>
              <l-ring-2
                size="40"
                stroke="5"
                stroke-length="0.25"
                bg-opacity="0.1"
                speed="0.8"
                color="white"
              ></l-ring-2>
            </span>
          </div>
        ) : selectedData ? (
          <div className="max-w-[40rem] mx-auto mt-10 text-white p-6">
            <div className="flex flex-col justify-center">
              {/* Token Icon and Name */}
              <div className="flex items-center space-x-4 mb-3">
                <div className="bg-gray-700 rounded-full w-12 h-12 flex items-center justify-center">
                  <span className="text-xl font-bold">
                    {selectedData.symbol[0]}
                  </span>
                </div>
                <div className="flex flex-row items-center">
                  <h1 className="text-2xl font-semibold">
                    {selectedData.name} {selectedData?.symbol}
                  </h1>
                  <span className="text-sm text-gray-400 ml-2">
                    {selectedData?.type || "ERC-20"}
                  </span>
                </div>
              </div>

              {/* Address with Copy and QR Icons */}
              <div className="flex items-center space-x-3">
                <a
                  href={`https://api.ideascan.io/address/${
                    selectedData?.contractAddress || selectedData?.address
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline truncate w-[280px] pl-2"
                >
                  {selectedData?.contractAddress || selectedData?.address}
                </a>
                <button
                  className="text-gray-400 hover:text-white"
                  onClick={() => handleCopy(selectedData?.contractAddress)}
                >
                  <FaCopy />
                </button>
                <button
                  className="text-gray-400 hover:text-white"
                  onClick={() => openModal()}
                >
                  <FaQrcode />
                </button>
              </div>

              {/* QR Code Modal */}
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="QR Code Modal"
                // className="w-[20rem] h-[25rem] absolute top-28 left-[40rem] rounded-lg bg-white flex flex-col items-center justify-center"
                className="w-full sm:w-[20rem] h-[25rem] absolute top-28 left-1/2 transform -translate-x-1/2 rounded-lg bg-white flex flex-col items-center justify-center"
                overlayClassName="overlay"
              >
                <h2 className="text-lg mb-4">Address QR Code</h2>
                <QRCodeSVG
                  value={selectedData?.contractAddress || selectedData?.address}
                  size={256}
                  bgColor={"#FFFFFF"}
                  fgColor={"#000000"}
                  level={"L"}
                />
                <button onClick={closeModal} className="mt-4 text-red-500">
                  Close
                </button>
              </Modal>

              {/* Token Info Section */}
              <div className="pl-2 py-4 rounded-lg space-y-4 sm:w-[40rem]">
                <div className="flex justify-between flex-col md:flex-row md:items-center">
                  <span className="text-gray-400">
                    {selectedData.total_supply ? "Max total supply" : "Balance"}
                  </span>
                  <span className="font-semibold text-left w-[8rem] text-ellipsis">
                    {selectedData?.balance > 0
                      ? (selectedData.balance / 1000000000000000000).toFixed(
                          5
                        ) + " Idea"
                      : selectedData?.balance === 0
                      ? "0 Idea"
                      : ""}
                    {selectedData.total_supply &&
                      selectedData.total_supply.slice(0, 18) + "..."}
                  </span>
                </div>
                <div className="flex justify-between flex-col md:flex-row md:items-center">
                  <span className="text-gray-400">
                    {selectedData.total_supply ? "Holders" : "Transactions"}
                  </span>
                  <span className="font-semibold text-left w-[8rem]">
                    {selectedData?.transactions != null
                      ? selectedData.transactions
                      : "0"}
                    {selectedData.holders && selectedData.holders}
                  </span>
                </div>
                <div className="flex justify-between flex-col md:flex-row md:items-center">
                  <span className="text-gray-400">
                    {selectedData.total_supply ? "Transfer" : "Gas Used"}
                  </span>
                  <span className="font-semibold text-left w-[8rem]">
                    {selectedData?.transfersCount && selectedData?.transactions}
                    {selectedData.gasused && selectedData.gasused}
                  </span>
                </div>
                <div className="flex justify-between flex-col md:flex-row md:items-center">
                  <span className="text-gray-400">
                    {" "}
                    {selectedData.total_supply
                      ? "Decimals"
                      : "Last Balance Updated"}
                  </span>
                  <span className="font-semibold text-left w-[8rem]">
                    {selectedData.decimals && selectedData.decimals}
                    {selectedData.lastupdate && selectedData.lastupdate}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          detailError && (
            <p className="text-red-500 text-center mt-4">{detailError}</p>
          )
        )
      ) : (
        <div className="mt-4 mx-auto xl:w-[85rem]">
          <div className="shadow-md rounded-lg p-4">
            <div className="flex justify-between items-center bg-[#0F2434] px-4 h-12 text-[12px] md:text-[14px] lg:text-lg">
              <h2 className="text-white">Search Results</h2>
              <h2 className="text-white">Category</h2>
            </div>

            {searchResults && searchResults.length > 0 ? (
              <ul>
                {searchResults?.map((result, index) => (
                  <li
                    key={index}
                    className="border-b border-[#0F2434] py-2 flex justify-between items-center cursor-pointer"
                    onClick={() => {
                      if (result?.address) {
                        fetchDetails(result.address);
                      } else if (result?.block_hash) {
                        navigate("/searchresult/searchblock", {
                          state: {
                            isLoading: true,
                            result: result.block_hash,
                          },
                        });
                      } else {
                        navigate("/searchresult/searchhash", {
                          state: {
                            isLoading: true,
                            result: result.tx_hash,
                          },
                        });
                      }
                    }} // Pass the token address when clicked
                  >
                    <div className="flex justify-between w-full mx-4 text-[8px] md:text-[12px] lg:text-[14px]">
                      <div className="flex flex-col md:flex-row justify-between  max-w-3xl">
                        <div className="flex justify-center items-center">
                          {result.icon_url ? (
                            <img
                              src={result.icon_url}
                              alt=""
                              className="w-5 h-5"
                            />
                          ) : (
                            <GiBrokenBone size={24} color="white" />
                          )}
                          <p className="pl-2 pr-1 text-blue-700">
                            {result?.name
                              ? `${result.name.slice(0, 15)}...`
                              : null}
                          </p>
                          <p className="text-blue-700">
                            {result.symbol
                              ? `${result?.symbol?.slice(0, 8)}...`
                              : null}
                          </p>
                        </div>
                        <div className="text-white">
                          {result.tx_hash &&
                            `${result?.tx_hash?.slice(0, 15)}...`}
                          {result.tx_hash &&
                            `${result?.tx_hash?.slice(23, 30)}`}
                          {result?.address &&
                            `${result?.address?.slice(0, 15)}...`}
                          {(result.block_hash &&
                            `${result?.block_hash?.slice(0, 15)}`) ||
                            null}
                          {result?.address &&
                            `${result?.address?.slice(23, 30)}`}
                          {result?.block_hash &&
                            `${result?.block_hash?.slice(23, 30)}`}
                        </div>
                      </div>
                      <div className="flex flex-col-reverse md:flex-row justify-between w-[2rem] md:w-[10rem]">
                        {result?.exchange_rate ? (
                          <p className="text-white">${result.exchange_rate}</p>
                        ) : (
                          <p className=""></p>
                        )}
                        <p className="text-white">{result.type}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-white text-center mt-4">No data found</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Searchres;
