import React from "react";

const Pagination = ({
  nextPage,
  changeCPage,
  prePage,
  numbers,
  currentpage,
}) => {
  return (
    <div>
      <nav>
        <ul className="text-white items-center flex flex-row justify-center bg-[#071120]">
          <li className="mr-3">
            <a
              href="#"
              onClick={prePage}
              className="bg-[#0C71BC] rounded-full py-1 px-2 sm:py-2 sm:px-3 text-[12px] sm:text-lg "
            >
              Prev{" "}
            </a>
          </li>
          {numbers.map((n, i) => (
            <li
              key={i}
              className={`mr-3 ${
                currentpage === n ? "active" : ""
              } hover:outline-blue-700 hover:outline hover:outline-2 hover:outline-offset-2 px-2 text-[8px] sm:text-lg`}
            >
              <a href="#" onClick={(e) => changeCPage(e, n)} className="">
                {n}
              </a>
            </li>
          ))}
          <li>
            <a
              href="#"
              onClick={nextPage}
              className="bg-[#0C71BC] rounded-full py-1 px-2 sm:py-2 sm:px-3 text-[12px] sm:text-lg"
            >
              Next
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
